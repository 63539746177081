import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import './index.css';


export default class Gallery extends Component {
    componentDidMount(){
        AOS.init({
          duration : 1500
        });
      } 
    render() {
        return (
           <div className='GallerySection'>
            <Container>
                    <h2  data-aos="zoom-in" className="GalleryTitle">GALLERY</h2>
                    <Row className='GalleryRow'>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='GalleryCard'>
                                <img className="FrontCard"src='image/Gallery/SymbiosisInaugralFunction.jpg' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                            </div> 
                        </Col>

                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='GalleryCard'>
                                <img className="FrontCard"src='image/Gallery/SymbiosisStage.jpg' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                            </div> 
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='GalleryCard'>
                                <img className="FrontCard"src='image/Gallery/SymbiosisLampLighting.jpg' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                            </div> 
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='GalleryCard'>
                                <img className="FrontCard"src='image/Gallery/FelicitationRajeevKhade-min.jpg' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                            </div> 
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='GalleryCard'>
                                <img className="FrontCard"src='image/Gallery/ASMFelicitation-min.jpg' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                            </div> 
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='GalleryCard'>
                                <img className="FrontCard"src='image/Gallery/MeetWithMr.jpg' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                            </div> 
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='GalleryCard'>
                                <img className="FrontCard"src='image/Gallery/conference.jpeg' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                            </div> 
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='GalleryCard'>
                                <img className="FrontCard"src='image/Gallery/AdientMeet.PNG' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                            </div> 
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='GalleryCard'>
                                <img className="FrontCard"src='image/Gallery/DYPatilSpeaker.JPG' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                            </div> 
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='GalleryCard'>
                                <img className="FrontCard"src='image/Gallery/IIEBMspeaker-min.JPG' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                            </div> 
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='GalleryCard'>
                                <img className="FrontCard"src='image/Gallery/DataCenterSummit.jpeg' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                            </div> 
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='GalleryCard'>
                                <img className="FrontCard"src='image/Gallery/ITspeaker.jpg' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                            </div> 
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='GalleryCard'>
                                <img className="FrontCard"src='image/Gallery/ASMSpeaker-min.jpg' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                            </div> 
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='GalleryCard'>
                                <img className="FrontCard"src='image/Gallery/SpeakerTechnologyTransformation.JPG' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                            </div> 
                        </Col>
                    </Row>
            </Container>
           </div> 
        );
    };
};


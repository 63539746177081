import React, { Component } from 'react';
import './index.css';


export default class UnderConstruction extends Component {
    render() {
        return (
            <div className="underConstructionClass">
                {/* <img src='image/maintenanceCopy.png' width="250px" height="250px"/> */}
                <h2 className="underConstructionTitle">THIS PAGE IS UNDER CONSTRUCTION</h2>
            </div>
          
        );
    };
};
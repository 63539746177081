import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import Navigationbar from './components/Navbar';
import HomePage from './components/Home';
import About from './components/About';
import Awards from './components/Awards';
import Associates from './components/Associates';
import ServicesSection from './components/Services';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Blogs from './components/BlogsDynamic';
import BlogPost from './components/BlogDetail';
import Gallery from './components/Gallery';
import UnderConstruction from './components/UnderConstruction';
import React, { Component }  from 'react';


function App() {
  return (
    
    <div>
          <Router>
          <Navigationbar/>
              <Routes>
              <Route path='/' element={<HomePage/>} />
              <Route path='/about' element={<About/>} />
              <Route path='/services' element={<ServicesSection/>}/>
              <Route path='/associates' element={<Associates/>}/>
              <Route path='/blogs' element={<Blogs/>} />
              <Route path="/blog/:id" element={<BlogPost/>} />
              <Route path='/awards' element={<Awards/>} />
              <Route path='/testimonials' element={<Testimonials/>} />
              <Route path='/gallery' element={<Gallery/>} />
              <Route path='/contact' element={<Contact/>} /> 
              <Route path='/underConstruction' element={<UnderConstruction/>} />
              </Routes>
          </Router>
    </div>
  );
}

export default App;

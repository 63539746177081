import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'reactjs-popup/dist/index.css';
import AOS from 'aos';
import './index.css';


export default function Contact (){
    const [show, setShow] = useState(false);
    AOS.init({
        duration : 1500
      });
    const handleClose = () => setShow(false);
    const EmailFunction = ()=>{
        return window.open('mailto:rajeev_khade@hotmail.com', '_blank','resizable=yes');
    }
    
    const LinkedInFunction = ()=>{
        return window.open('https://www.linkedin.com/in/rajeevkhade/','_blank','resizable=yes');
    }

    const FacebookFunction = ()=>{
        return window.open('https://www.facebook.com/rajeevkhade.in/','_blank','resizable=yes');
    }

    const TwitterFunction = ()=>{
        return window.open('https://www.twitter.com/rajeev_khade/','_blank','resizable=yes');
    }

        const form = useRef();
        const handleShow = (e) => {
           
            e.preventDefault();
            // service_d1veh4d, service_jqfg7sc
            // template_kf51eji
            // 7PmqTM86N6Ntopz1U
            
            emailjs.sendForm('service_c3jj127', 'template_j9at2dp', form.current, 'X2n1UGBwrShPunSVt')
              .then((result) => {
            setShow(true);
                e.target.reset();
                  console.log(result.text);
              }, (error) => {
                  console.log(error.text);
              });
          };
      return (
        <div className="contactClass">
        <h2 data-aos="zoom-in" className="ContactTitle">CONTACT US</h2>
        <form ref={form} onSubmit={handleShow} className="container formClass">
            <div>
                <label className="labelClass">Name:</label>
                <input type="text" name="userName" className="inputClass" required />
            </div>
            <div>
                <label className="labelClass">Email:</label>
                <input type="email" name="userEmail" className="inputClass" required/>
            </div>
            <div>
                <label className="labelClass">Phone:</label>
                <input type="tel" name="userPhone" pattern="[0-9]{10}" title="Enter valid phone number of 10 digits" className="inputClass" required/>
            </div>
            <div>
            <label className="labelClassMessage">Message:</label>
                <textarea type="text" name="userQuery" className="inputTextClass" maxLength="5000" required></textarea>
            </div>
                <input type="submit" value="Submit" className='SubmitClass'/>
                      <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>Thanks for submitting the response. We'll get back to you soon</Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          </Modal.Footer>
        </Modal>
        </form>
        <div className='ContactDetailsClass container'>
                <div className="LinkedInIcon" onClick={EmailFunction}>
                    <i className="fa fa-envelope fa-2x "></i>
                </div>
                <div className="LinkedInIcon" onClick={LinkedInFunction}>
                    <i className="fa fa-linkedin fa-2x "></i> 
                </div>
                <div className="FacebookIcon" onClick={FacebookFunction}>
                    <i className="fa fa-facebook fa-2x "></i>
                </div>
                <div className="TwitterIcon" onClick={TwitterFunction}>
                    <i className="fa fa-twitter fa-2x "></i> 
                </div>
                <div className="phoneIcon">
                    <i className="fa fa-phone fa-2x"></i> 
                    <span className="phoneDetails">India: +91 9881904349 </span>
                    <span className="phoneDetails">USA: +1 (409)434-9845 </span>
                </div>
        </div>
        </div>
      );
  }
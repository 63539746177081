import  React from 'react';
import './index.css';
import MainSection from '../Mainsection';

function HomePage(){
    return (
      <MainSection/>
  );
};
  
export default HomePage;
import React, { Component } from 'react';
import AOS from 'aos';
import './index.css';


export default class Testimonials extends Component {
    componentDidMount(){
        AOS.init({
          duration : 1500,
          disable: 'mobile'
        });
      } 
    render(){
        return (
            <div className='TestimonalMainsection' style={{ "paddingTop": "8rem"}}>
                <h2 data-aos="zoom-in" className='TestimonialsTitle'>TESTIMONIALS</h2>
                <div className='TestimonialsSection container'>
                    <div className='TestimonialFirst'>
                        <img data-aos="fade-right" data-aos-offset="400"  src="../../image/Testimonials1.jpeg" width="250px" height="250px" alt="TestimonialsImage1" />
                        <div data-aos="fade-left" data-aos-offset="200" className='TestimonialsFirstDescription'>Rajeev is a technically strong IT Manager with a lot of experience working in a manufacturing environment. He is very hard working and dedicated to serving his customers and company. He has good leadership skills and is able to work well with people at all levels of the organization.<br /><p style={{"textAlign":"end"}}>Antony D'Cruz<br />Execution Focused Senior IT Leader with Global Experience</p></div>
                    </div>
                    <div className='TestimonialSecond'>
                        <div data-aos="fade-right"  className='TestimonialsSecondDescription'>Rajeev was instrumental in the seamless, flawless and schedule bound migration of SAP System & entire IT infrastructure from India to Europe. He not only executed the project to perfection but exceeded his brief by implementing cost effective upgrades in parallel with the changeover. He was able to commendably handle his multi-location, multi-business role in the group.
                            <br />He was also able to get the best value out of the outsourced IT Helpdesk my motivating the staff there thus proving his people management skills too. While being an IT aficionado keeping pace with state of art technology, Rajeev is focused on recommending, and implementing cost appropriate solutions.<br /><p style={{"textAlign":"end"}}>Gajinder Bains <br />MD & CEO - GS Auto International Ltd</p></div>
                        <img data-aos="fade-left"  src="../../image/Testimonials2.jpeg" width="250px" height="250px" alt="TestimonialsImage2" />
                    </div>
                    <div className='TestimonialThird'>
                        <img  data-aos="fade-right" src="../../image/Testimonials3.jpeg" width="250px" height="250px" alt="TestimonialsImage3" />
                        <div  data-aos="fade-left" className='TestimonialsThirdDescription'>Rajeev has good ability of multi-tasking which helped him to set up entire IT infrastructure and systems at THSL during inception stage. He is a team player with sound technical knowledge. He contributed in implementing SAP along with core team and it was a major achievement. I wish all the best to Rajeev.<br /><p style={{"textAlign":"end"}}>Narendra Chavan<br />Chief Executive Officer Nanjing Tata AutoComp Systems Limited, China</p></div>
                    </div>
                </div>
            </div>

        );
}
};
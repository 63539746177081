import  React, { Component } from 'react';
import AOS from 'aos';
import './index.css';

export default class MainSection extends Component {
  componentDidMount(){
    AOS.init({
      duration : 1500
    });
  } 
  render(){
    return (
      <div>
        <div className='MainContainer'>
        <div className="InnerContainer">
          {/* <img src="../../../public/image/christmass.jpg" width="250px" height="250px" alt="greeting" style={{"paddingTop":"250px"}}/> */}
          <div data-aos="zoom-in-up" className='MainTitle'>
            {/* <img src="../../image/LogoRajeevKhade.png" width="100" height="100" alt="Brand Logo"/>
            <br/> */}
            Hi, I'm Rajeev Khade</div>
            <div data-aos="zoom-in-up" className='MainDescription'>IT Professional, Advisor, Consultant <br />A Certified Independent Director by Ministry of Company Affairs.</div>
          <div data-aos="zoom-in-up" className='MainSubDescription'>Consultant & Advisor on Digitalization, Robotic Process Automation,<br/> Mfg. Process excellence, IT security & IT strategies.<br/>Design & flawless execution of IT projects.<br/> Mentor to startup companies on behalf of CII.<br/>Member of Mfg. working Group IET Future Tech Panel.</div>
        </div>
        </div>
        
      </div>
      
  );
    }
};
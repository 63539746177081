import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import './index.css';


export default class Awards extends Component {
    componentDidMount(){
        AOS.init({
          duration : 1500
        });
      } 
    render() {

        return (
            <div className='AwardsSection'>
                <Container>
                    <h2  data-aos="zoom-in" className="AwardsTitle">ACHIEVEMENTS AND AWARDS</h2>
                    <Row className='AwardsRow'>
                    <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award8.JPG' width="250px" height="250px" alt="AwarCertificate of CIO 22 Awards & Excellenced7" />
                                <div className='backSide'> <p>Certificate of CIO 22 Awards & Excellence : This award was presented by CIO Axis & CISO Connect at an event 7th edition of Innovative awards in recognition of the relentless services & sincere service to the organisation through implementating IT solutions,Policies & Technologies in an innovative way.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award2-min.jpg' width="250px" height="250px" alt="Catalyst CIO Award" />
                                <div className='backSide'> <p>Catalyst CIO Award : This award was given by Jointly by Business Transformation Asia and GEC Media group in recognition of the initiative taken as Catalyst for various business transformations undertaken.</p></div>
                            </div>
                        </Col>
                        {/* <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award4-min-min.jpeg' width="250px" height="250px"  alt="IT Strategist of the year" />
                                <div className='backSide'> <p>IT Strategist of the year : This award was given by Inflection in an event jointly organized along with Alden Global Value Advisors & NASSCOM Centre of Excellence - IOT & AI in recognition of the various strategies initiated during the year for adding value to business.</p></div>
                            </div>
                        </Col> */}
                        {/* <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award5-min-min.JPG' width="250px" height="250px" loading="lazy" alt="Data center & IT Infrastructure Icon" />
                                <div className='backSide'> <p> Data center & IT Infrastructure Icon : This award was given by CIO Power list in recognition of the various measures taken to inhance the proactive measures undertaken to enhance the working of Data center and make it more efficient.</p></div></div>
                        </Col> */}
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award6-min-min.JPG' width="250px" height="250px" alt="Data center & IT Infrastructure Icon" />
                                <div className='backSide'> <p> Data center & IT Infrastructure Icon : This award was given by CIO Power list in recognition of the various measures taken to inhance the proactive measures undertaken to enhance the working of Data center and make it more efficient.</p></div></div>
                        </Col>
                        {/* <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award7-min-min.JPG' width="250px" height="250px" alt="Eminent CIO of India" />
                                <div className='backSide'> <p>Eminent CIO of India : This award was given by Varinda at an event " 16th Infotech Forum 18 " in recognition of the various initiative and projects undertaken during the year as a CIO to transform the business.</p></div></div>
                        </Col> */}
                        {/* <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award10-min-min.jpg' width="250px" height="250px" alt="CIO Conclave Award" />
                                <div className='backSide'> <p>CIO Conclave Award : This award was given by SAP in an event " CIO COnclave and Awards 2020 " as CIO of the year Recognition organized by UBS Forum.</p></div>
                            </div>
                        </Col> */}
                        {/* <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award28-min-min.JPG' width="250px" height="250px" loading="lazy" alt="Change Agents 200" />
                                <div className='backSide'> <p>Change Agents 200 : This award was presented by Enterprise IT world for being one of the top Change agent on 8th Sept 2017 in a function held in New Delhi India.</p></div></div>
                        </Col> */}
                        {/* <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award1-min.PNG' width="250px" height="250px" loading="lazy" alt="Adient" />
                                <div className='backSide'> <p>Adient : This award was honored at an event for the decade of service to the organisation.</p></div></div>
                        </Col> */}
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award11-min.jpg' width="250px" height="250px" loading="lazy" alt="CIO Manufacturing ICON" />
                                <div className='backSide'> <p>CIO Manufacturing ICON: This award was given by CORE Media Centre of Recognition & Excellence at an event CIO Power list 2020 in recognition of the IT services to the Organisation.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award12-min.PNG' width="250px" height="250px" loading="lazy" alt="CIO Manufacturing ICON" />
                                <div className='backSide'> <p>CIO Manufacturing ICON: This award was given by CORE Media Centre of Recognition & Excellence at an event CIO Power list 2020 in recognition of the IT services to the Organisation.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award13-min.jpg' width="250px" height="250px" loading="lazy" alt="Certificate of Recognition" />
                                <div className='backSide'> <p>Certificate of Recognition : COVID 19 Super Hero CIO : This certificate was given in recognition of the extra ordinary work done to power the business during the the difficult time of COVID 19 by use of various innovative ways to ensure business continuity. The said event was organized by IT Enterprise World powered by Ctrls Data Centres.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award14-min.jpg' width="250px" height="250px" loading="lazy" alt="Certificate of Recognition" />
                                <div className='backSide'> <p>Certificate of Recognition : COVID 19 Super Hero CIO : This certificate was given in recognition of the extra ordinary work done to power the business during the the difficult time of COVID 19 by use of various innovative ways to ensure business continuity. The said event was organized by IT Enterprise World powered by Ctrls Data Centres.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award15-min.jpg' width="250px" height="250px" loading="lazy" alt="CXO Excellence Award 2021" />
                                <div className='backSide'> <p>CXO Excellence Award 2021 : This award was given by IBM in an event Tech Summits & Awards in recognition of the extraordinary technology management & innovations in Operations management combined with leadership skills. The said event was powered by Snowflake, Veeam & Tech Plus Media.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award16.JPG' width="250px" height="250px" loading="lazy" alt="Cyber Security Lifetime Achievement Award" />
                                <div className='backSide'> <p>Cyber Security Lifetime Achievement Award : This award was conferred in recognition of the immense contribution to the cyber security domain on a global level by Enterprise IT world in a security Symposium</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award17-min-min.jpg' width="250px" height="250px" loading="lazy" alt="Data Center Summits & Award" />
                                <div className='backSide'> <p>Data Center Summits & Award : This award was given at a function " Data Center Summits & Awards 2017 " organized by UBS forums in recognition of the efforts taken to enhance the data center function.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award18-min.jpg' width="250px" height="250px" loading="lazy" alt="Elite Excellence Award 2020" />
                                <div className='backSide'> <p>Elite Excellence Award 2020 : This award was given in recognition of the achievement by Elite CIO Council</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award19-min-min.jpg' width="250px" height="250px" loading="lazy" alt="Data Center Champions Award 2021" />
                                <div className='backSide'> <p>Data Center Champions Award 2021 :  This award was given by Enterprise IT world at a function jointly organized with Adana Connex and CtrlS</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award20-min-min.jpg' width="250px" height="250px" loading="lazy" alt="Transformational CIO of the year Award" />
                                <div className='backSide'> <p>Transformational CIO of the year Award : This award was given for having successfully managed the transformational Journey in data center management by use of Innovative strategies at an event organized by Enterprise IT world.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award21-min-min.JPG' width="250px" height="250px" loading="lazy" alt="Johnson Control Automotive Ltd. Mfg. IT Team" />
                                <div className='backSide'> <p>Johnson Control Automotive Ltd. Mfg. IT Team : This award was given as a 10 year marker Excellence Merit award in year 2013.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award22-min-min.JPG' width="250px" height="250px" loading="lazy" alt="Johnson Control Automotive Ltd. Mfg. IT Team" />
                                <div className='backSide'> <p>Johnson Control Automotive Ltd. Mfg. IT Team : This Award was presented in the year 2013 on the successive deployment of Purchase Order software deployment / work around solution which was integrated with the ERP.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award23-min-min.JPG' width="250px" height="250px" loading="lazy" alt="Johnson Control Innovative team award for the year 2015" />
                                <div className='backSide'> <p>Johnson Control Innovative team award for the year 2015: This award was given as a respect for 10 year marker excellence Merit award for development of various innovative ideas in IT.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award24-min-min.JPG' width="250px" height="250px" loading="lazy" alt="Johnson Control Automotive Ltd." />
                                <div className='backSide'> <p>Johnson Control Automotive Ltd. This award was presented for successful deployment of Foam Tracking Software in the production line as a 10 year marker excellence Merit award.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award25-min-min.JPG' width="250px" height="250px" loading="lazy" alt="Johnson Control Automotive Ltd." />
                                <div className='backSide'> <p>Johnson Control Automotive Ltd. This award was presented on successful deployment of Material Requirement Planning ( MRP ) integrated with ERP in the year 2015 which was connected with the 10 year Marker Excellence Merit award.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award32.JPG' width="250px" height="250px" loading="lazy" alt="Technology Leader Award for 2021" />
                                <div className='backSide'> <p>Technology Leader Award for 2021: This award was presented for the outstanding contribution in the field of IT Infrastructure and Network. This award was presented by Tek Q Global CIO Forum & Business Connect in May 2021.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award33.jpg' width="250px" height="250px" loading="lazy" alt="TekQ Super CIO Award" />
                                <div className='backSide'> <p>TekQ Super CIO Award : This award was presented by Tek Q Global CIO Forum and Business Connect in July 2020.</p></div>
                            </div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award34.jpeg' width="250px" height="250px" loading="lazy" alt="Global CIO Forum" />
                                <div className='backSide'> <p>Global CIO Forum: This award was presented by the Global CIO Forum at the world CIO 200 Summit in Nov 2021</p></div>
                            </div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award29-min.jpg' width="250px" height="250px" loading="lazy" alt="Certificate of Excellence" />
                                <div className='backSide'> <p>Certificate of Excellence : This award was presented in recognition of the leadership dedication and excellence presented  in securing Organisations digital resources at the 10th Edition InfoSec Mastero Awards organized jointly by CIO Axis/CISO Connect & Checkpoint.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                < img className='frontSide' src='image/award30.JPG' width="250px" height="250px" loading="lazy" alt="Cloud Champs" />
                                <div className='backSide'> <p>Cloud Champs :  This award was presented at a function Insights CXO Awards 2021 in recognition of Innovation & Excellence.</p></div></div>
                        </Col>
                        <Col className="p-3 col-lg-4 col-md-6 col-sm-6 col-12 AwardImage mainContainer">
                            <div className='theCard'>
                                {/* < img className='frontSide' src='image/award31.jpg' width="250px" height="250px" loading="lazy" alt="Award30" />
                                <div className='backSide'> <p>Description about the image</p></div>*/}
                            </div> 
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };
};


import React from 'react'
import { BlogDataJson } from './BlogData'
import { Link } from "react-router-dom";
import './index.css';
import AOS from 'aos';

function index() {
      AOS.init({
            duration : 1500
          });

      return (
            <>
                  <title>Blogs by Rajeev Khade</title>
                  {/* <meta
                              name="description"
                              content="Find our latest blogs on Atmospheric Water Generation"
                        /> */}
                   <div className="BlogsClass">
                        <h2 data-aos="zoom-in"  className='BlogsTitle'>BLOGS</h2>

                  <section className='my-3 my-lg-5 container '>
                        <div className='  d-flex flex-lg-row flex-column w-100 flex-wrap justify-content-between' >

                              {/* <ReactPictureGrid data={data} backDropColor={"#335b8a"} showTitle gap={10} /> */}

                              {
                                    // 
                                    BlogDataJson?.map((item, index) => {
                                          return <>
                                                <div key={item.id} className={`blogcard_common ${index === 0 ? ("width50") : (index == 1 ? ("width30") : (index == 2 ? ("width40") : (index == 3 ? ("width25") : (index == 4 ? ("width25") : (index == 5 ? ("width40") : (index == 6 ? ("width50") : (index == 7 ? ("width40") : (index == 8 ? ("width25") : (index == 9 ? ("width30") : (index === 10 ? ("width50") : (index === 11 ? ("width40") : (index === 12 ? ("width25") : (index === 13 ? ("width50") : ("width30"))))))))))))))} m-2 ImageContainer `}>
                                                      <Link data-aos="zoom-out" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-once="false" to={`/blog/${item.id}`}>
                                                            <img priority="true" className='w-100' src={item?.image} width={700} height={400} alt="Blog Image" />
                                                            <span>{item?.title}</span>
                                                      </Link>
                                                </div>
                                          </>
                                    })
                              }
                        </div>



                  </section>

                  </div>
                  {/* <Footer /> */}
            </>
      )
}

export default index
import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from "react-slick";
import './index.css';
import AOS from 'aos'


export default class About extends Component {
  componentDidMount() {
    AOS.init({
      duration : 1500
    });
  }
  render() {
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
            // initialSlide: 2
          }
        },
        {
          breakpoint: 414,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    };
  
  return (
    <div className="AboutSection">
      <Container>
        <div className='AboutContainer'>
          <div className='AboutTitle1'>ABOUT RAJEEV</div>
          <img src='image/RajeevKhadeAbout-min-min.JPG' alt='Profile' width='500px' height='500px' className='AboutImage'/>  
          <div className='AboutText'>
          <div data-aos="zoom-in" className='AboutTitle2'>ABOUT RAJEEV</div>
            <div className='AboutFirstSection'>
              A Seasoned Senior management IT Professional with focused approach on Digitalization, IoT, LCA, Industry 4.0, RPA, Cloud migration for operational excellence, turn around strategy & Branding.
              Member- Manufacturing Working Group, IET Future Tech Panel
            </div>
            <div className='AboutSecondSectionTitle'>
              Key Competencies :
            </div>
            <div className='AboutSecondSection'>
              <ul>
                <li>Designing Group level IT Strategies, Policies & IT road maps for achieving growth through process innovation, progressive change management.</li>
                <li>Architectural design & establishment of entire IT set up across various geographies for individual and group companies.</li>  
                <li>Up gradation of ERP systems to achieve process excellence.</li>
                <li>Rich experience in converting IT projects from conceptual/architectural design to handover stage.</li>
                <li>Building IT road maps, formulation of key IT strategies, Policies & procedures and establishment of centralized IT setup for entire organization/group.</li>
            
              </ul>
              </div>
            <div className='AboutThirdSectionTitle'>
              Expertise :
            </div>
            <div className='AboutThirdSection'>
              <ol>
                <li>Digitalisation, Innovation, IoT, Industry 4.0 LCA</li>
                <li>Strategic IT Planning for short term and long term IT projects with a focus on Process Excellence, Optimization and use of latest technologies to achieve competitive edge over competitors.
                  </li>
                <li>Rich exposure to design of IT Policies, procedures, road maps, standardization and adoption of best practices.</li>
                <li>Strong business acumen for leveraging technology with a focus to bring in business innovation, operational efficiency with competitive advantage.</li>
                <li>Effective business enabler / leader with a keen focus on Change management, Organization Leadership, relationship building.</li>
                <li>Architectural design and Implementation of IT Infrastructure for data centers, Migration to Cloud. disaster recovery sites, Engineering design centers and Business Continuity plans.</li>
                <li>Evaluation, Selection and Implementation of ERP for individual and group companies.</li>
                <li>Process enhancements in existing applications to bring in optimization.</li>
              </ol>
            </div>

          </div>
        </div>
        <div>
          <div data-aos="fade-down" className="AboutAwardsTitle">ACHIEVEMENTS AND AWARDS</div>
          <div className="AboutAwardsCarousel">
            <Slider {...settings}>
              <div className="CarouselDiv">
                <img src='image/RajeevKhadeAward1.jpeg' alt='Award 1' loading="lazy" width='260px' height='260px' className='AboutAwardImage'/>
              </div>
              <div className="CarouselDiv">
               <img src='image/RajeevKhadeAward2-min.jpeg' alt='Award 2' loading="lazy"  width='260px' height='260px' className='AboutAwardImage'/>
              </div>
              <div className="CarouselDiv">
                <img src='image/RajeevKhadeAward3-min-min.jpeg' alt='Award 3' loading="lazy" width='260px' height='260px' className='AboutAwardImage'/>
              </div>
              <div className="CarouselDiv">
                <img src='image/RajeevKhadeAward4.jpeg' alt='Award 4' loading="lazy" width='260px' height='260px' className='AboutAwardImage'/>
              </div>
              <div className="CarouselDiv">
                <img src='image/RajeevKhadeAward5.jpeg' alt='Award 5'loading="lazy"  width='260px' height='260px' className='AboutAwardImage'/>
              </div>
            </Slider>
          </div>
          <div className='AboutAwardsButton'>
            <Button className= "AboutButton" href='/awards'>View Awards</Button>
          </div>
        </div>
      </Container>
    </div>
  );
};
};


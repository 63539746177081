import  React , { Component }  from 'react';
import AOS from 'aos';
import './index.css';

export default class Services extends Component {
  componentDidMount(){
    AOS.init({
      duration : 1500
    });
  } 
  render() {

    return (
      <div className='ServiceMainSection' style={{"paddingTop": "8rem",}}>
        <h2 data-aos="zoom-in" className='ServiceTitle'>SERVICES</h2>
        <div className='ServicesSection container'>
              <ul>
                <li>Advisor and Mentorship guidance to start up companies in designing strategies for growth.</li>
                <li>Consultant for Digitalization, Low cost automation, Robotic Process automation & various automation to achieve process excellence.</li>  
                <li>Selection and preparation of Industry 4.0 projects/plans and execution of the same.</li>
                <li>Designing of IT road maps and tactical plans for flawless execution of various projects. </li>
                <li>Guidance on selection of right applications best suited for individual and group companies.</li>
                <li>Technology solution provider to business to improve the processes and add value to business.</li>
                <li>Architectural design and implementation of IT Infrastructure with latest technology standards and making it future ready.</li>
                <li>Consultancy on Infrastructure for green field and brown field projects by use of technologies like digital twin.</li>
                <li>Online consultancy for various projects from day one of implementation till flawless execution and revamp as necessary.</li>
                <li>Sizing of IT Infrastructure for new projects.</li>
                <li>Hardening of existing IT Infrastructure to secure the core layer from external attacks and Ransomware protection.</li>
                <li>Define IT Policies & Procedures for process oriented approach for IT Operations and flawless execution of projects.</li>
              </ul>
        </div>
      </div>
      
  );
    }
};
import React, { useState, useEffect, useRef,useRouter } from 'react'
import '../../../src/components/Blogs/index.css'
import { BlogDataJson } from '../BlogsDynamic/BlogData'
import { useLocation } from 'react-router-dom';
// import Image from 'next/image'
// import Navbar from '../../utils/Navbar'
// import Footer from '../../utils/Footer'
// import heading from '../../../public/images/h1.png'
// import img5 from '../../../public/images/blogs/5.png'
// import { useRouter } from 'next/router'
// import { AiOutlineArrowLeft } from 'react-icons/ai'
// import Link from 'next/link'
// import Skeleton from 'react-loading-skeleton'
// import 'react-loading-skeleton/dist/skeleton.css'


function SingleBlog() {
    const [isVisible, setIsVisible] = useState(false);
    const imageRef = useRef(null);
    const [Blog, setBlog] = useState({});
    const router = useLocation()
    const  id  = router.pathname[6]
    useEffect(() => {
        const getdata = () => {
            const data = BlogDataJson.filter((item) => item.id == id);
            setBlog(data[0]);
        }
        getdata()
    }, [id])

    return (
        <>
           <div className="BlogsContent">
                <div className='container'>
                <figure className="image pilotHeading classes.SingleBlog_head">
                    <img src={Blog?.image} alt='Blog First' className='BlogImage'/>  
                    <figcaption>{Blog?.title}</figcaption>
                </figure>
                <div className='' dangerouslySetInnerHTML={{ __html: Blog?.body }}>
            </div>
            {/* <div className='container'> */}
                
            {/* </div>  */}
            </div>
            {/* <div className='d-flex justify-content-center mb-0'>
                <figure className={`pilotHeading classes.SingleBlog_head`}> */}
                    {/* <img priority src={heading} alt="Header Background"></img> */}
                    {/* <figcaption>{Blog?.title}</figcaption>
                </figure>
            </div> */}

            {/* <div className='container my-4'>
                <strong><p className={`py-2 mb-0  `}>Published On {Blog?.date}</p></strong> */}
                {/* {
                    isVisible
                        ?
                        <Image className='w-100 ' ref={imageRef} onLoad={() => setIsVisible(true)} priority src={Blog?.image} style={{ objectFit: "cover" }} width={900} height={400} alt="blog image" />
                        : <Skeleton height={400} />
                } */}
                {/* <img className='w-100 ' ref={imageRef} onLoad={() => setIsVisible(true)} priority="true" src={Blog?.image} style={{ objectFit: "cover" }} width={900} height={400} alt="blog image" />
            </div>

            <div className='col-12 container d-flex justify-content-between flex-lg-row flex-column-reverse'>
                <div className=' col-12 col-lg-8' dangerouslySetInnerHTML={{ __html: Blog?.body }}>
                </div> */}


                {/* <div className={`col-lg-3 my-3 my-lg-0 col-12 align-self-start ${classes.tableOfContent}`}>
                    <div className=' border text-center py-2 bg-warning text-white'>
                        <h5 className='mb-0'>Table Of Content :</h5>
                    </div>
                    <div className='border px-3  py-3'>
                        <ul>
                            {
                                Blog?.tableOfContent?.map((item, index) => {
                                    return <li className={`${classes.tableOfContent_list}`} key={index}>{item}</li>
                                })
                            }
                        </ul>
                    </div>
                </div> */}
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default SingleBlog